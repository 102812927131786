.formContentBox[data-v-2d91d6f6] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-2d91d6f6] {
  width: 100%;
  overflow: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 12px;
  height: 100%;
}
.formTopic[data-v-2d91d6f6] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}